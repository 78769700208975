<template>
  <div
    class="login"
    :class="$store.state.navigation ? 'xs-pa' : 'pa-10'"
    :style="'width:' + carouselHeight + 'px'"
  >
    <div class="_title">用户登陆</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="loginFrom.email"
        :rules="rules.emailRules"
        label="邮箱"
        required
      />
      <v-text-field
        v-model="loginFrom.passwd"
        :rules="rules.passwd"
        type="password"
        label="密码"
      />
      <div class="d-flex align-center">
        <v-text-field
          v-model="loginFrom.code"
          required
          label="验证码"
          :rules="rules.code"
        />
        <img
          class="ml-4"
          style="width: 100px; height: 50px"
          @click="getCode"
          :src="code"
          alt=""
        />
      </div>
      <v-btn
        :disabled="!valid"
        color="success"
        block
        class="mt-4"
        @click="handleLogin"
      >
        登录
      </v-btn>
    </v-form>
    <div class="other mt-8 d-flex justify-space-between">
      <p>
        没有账号？
        <router-link :to="{ path: 'register' }"
          ><span>立即注册</span>
        </router-link>
      </p>
      <p>
        <router-link :to="{ path: 'forget' }"
          ><span>忘记密码？</span></router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      valid: true,
      redirect: '',
      loginFrom: {
        email: '',
        passwd: '',
        code: '',
        uuid: '',
      },
      code: '',
      rules: {
        emailRules: [
          (v) => !!v || '请输入邮箱',
          (v) => /.+@.+\..+/.test(v) || '邮箱格式不正确',
        ],
        passwd: [(v) => !!v || '请输入密码'],
        code: [(v) => !!v || '请输入验证码'],
      },
    }
  },
  computed: {
    /* eslint-disable */

    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 340
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 500
        case 'xl':
          return 500
      }
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
        }
      },
      immediate: true,
    },
  },

  created() {
    this.getCode()
  },
  methods: {
    getCode() {
      this.$request({
        url: '/user/captchaImage',
        method: 'get',
      }).then((res) => {
        this.code = 'data:image/gif;base64,' + res.data.img
        this.loginFrom.uuid = res.data.uuid
      })
    },
    handleLogin() {
      let data = Object.assign({}, this.loginFrom)
      data.passwd = this.$md5(this.loginFrom.passwd)
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('Login', data)
          .then(() => {
            this.$router.push({ path: this.redirect || '/user' }).catch(() => {})
          })
          .catch(() => {
            this.getCode()
          })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './login.less';
</style>
